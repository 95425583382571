
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/web-ui/dist/twh/apps/CategoryMenuBar/Index.css';

// override of the default css in footer-render-service to display the cim logo
.hz-Footer .hz-FooterContainer .hz-Footer-cim {
  display: block;
}
